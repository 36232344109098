<template>
  <v-chip label small :color="label.color" :dark="dark">
    <v-icon left small>
      {{ label.icon }}
    </v-icon>
    {{ label.name }}
  </v-chip>
</template>

<script>
export default {
  props: {
    label: {
      type: Object,
      default: () => {}
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
