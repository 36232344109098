<template>
  <div>
    <!-- Heading -->
    <div class="mb-6">
      <div
        v-if="form.name"
        class="text-h3 mb-3 font-weight-bold"
        v-text="form.name"
      />
      <div v-if="form.body">
        {{ form.body }}
      </div>
    </div>

    <template v-for="(element, index) in form.formSectionItems">
      <models-form-item-parent-element
        :key="`${form.id}-element-${index}`"
        :form-item="element"
        :class="`${index !== 0 ? 'mt-2' : ''}`"
      />
    </template>

    <div class="mt-4">
      <models-form-delete-dialog :form="form" />
    </div>
  </div>
</template>

<script>
/*
 *  For filling form
 * */
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    }
  },
  watch: {},
  created() {
    this.showForm({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      showForm: "form/show"
    })
  }
};
</script>

<style scoped></style>
