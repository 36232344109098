<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon plain v-bind="attrs" v-on="on">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Element löschen">
      <template v-slot:text>
        <template v-if="formItem.children && formItem.children.length > 0">
          Sie können dieses Element nicht löschen, da sich noch Unter-Elemente
          in diesem befinden.
        </template>
        <template v-else>
          Wollen Sie das Element wirklich löschen?
        </template>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn
          color="error"
          :disabled="formItem.children && formItem.children.length > 0"
          :loading="loading"
          @click="submit"
        >
          Löschen
          <v-icon right dark>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    item: {},

    // functional
    dialog: false,
    loading: false
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    }
  },
  watch: {
    itemProp(val) {
      this.section = val;
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      destroyFormItem: "formItem/destroy"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.destroyFormItem({ id: this.formItem.id })
        .then(r => {
          this.form = { ...r.data.form };
          this.$snotify.success("Das Element wurde erfolgreich gelöscht.");
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Löschen des Elements ist ein Fehler aufgetreten."
          );
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
