<template>
  <v-form
    ref="form"
    v-model="isValid"
    lazy-validation
    @submit.prevent="submit()"
  >
    <div class="text-overline">
      Allgemein
    </div>

    <v-select
      v-model="user.title"
      label="Titel"
      :items="titleItems"
      filled
      required
      clearable
    />

    <v-text-field
      v-model="user.first_name"
      filled
      label="Vorname"
      :rules="[rules.required, rules.short_text]"
      required
      :counter="280"
    />

    <v-text-field
      v-model="user.last_name"
      filled
      label="Nachname"
      :rules="[rules.required, rules.short_text]"
      :counter="280"
    />
    <div class="text-overline mt-4">
      Kontakt
    </div>
    <v-text-field
      v-model="user.email"
      filled
      label="E-Mail"
      :rules="[rules.required, rules.short_text, rules.email]"
      :counter="280"
    />

    <v-text-field
      v-model="user.phone1"
      filled
      label="Telefon"
      :rules="[rules.short_text, rules.phone]"
      :counter="280"
    />

    <v-text-field
      v-model="user.phone2"
      filled
      label="Mobil"
      :rules="[rules.short_text, rules.phone]"
      :counter="280"
    />

    <div class="text-overline mt-4">
      Adresse
    </div>

    <v-text-field
      v-model="user.address1"
      filled
      label="Straße und Hausnummer"
      :rules="[rules.short_text]"
      :counter="280"
    />

    <v-text-field
      v-model="user.address2"
      filled
      label="Adresszusatz"
      :rules="[rules.short_text]"
      :counter="280"
    />

    <v-row>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="user.zip"
          filled
          label="PLZ"
          :rules="[rules.short_text]"
          :counter="280"
        />
      </v-col>
      <v-col cols="12" md="7">
        <v-text-field
          v-model="user.city"
          filled
          label="Ort"
          :rules="[rules.short_text]"
          :counter="280"
        />
      </v-col>
    </v-row>

    <div v-if="user.type === 'intern'">
      <div class="text-overline mt-4">
        Mitarbeiterspezifisch
      </div>
      <div class="text-overline mt-4">
        Profilbild
      </div>

      <img
        v-if="user.image_url"
        :src="user.image_url"
        alt="Profilbild"
        style="max-height: 100px; max-width: 300px;"
      />
      <util-file-drop :file-prop="file" @pass="file = $event" />
    </div>

    <div>
      <div class="text-overline mt-4">
        Zugangsdaten
      </div>
      <v-text-field
        v-model="user.password"
        type="password"
        clearable
        filled
        :rules="[rules.password_length]"
        label="Passwort"
      />

      <v-text-field
        v-model="user.password_confirmation"
        filled
        type="password"
        clearable
        :rules="[rules.password_length]"
        label="Passwort bestätigen"
      />
    </div>

    <div class="d-flex justify-end mt-6">
      <v-btn
        class="ml-2"
        color="primary"
        type="submit"
        :disabled="!isValid"
        :loading="loading"
      >
        Speichern
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    typeProp: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    loading: false,
    isValid: false,
    user: {
      title: null,
      first_name: "",
      last_name: "",
      email: "",
      phone1: "",
      phone2: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      password: "",
      customer_type_id: null,
      company_id: null,
      is_invited: true,

      label: "",
      teams: []
    },
    file: ""
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    titleItems() {
      return this.$store.getters["application/userTitles"];
    }
  },
  watch: {
    $route() {
      this.showUser();
      this.indexCustomerTypes();
    }
  },
  created() {
    this.showUser();
    this.indexCustomerTypes();
  },
  methods: {
    indexCustomerTypes() {
      this.axios
        .get("/customer-types")
        .then(res => {
          this.customerTypes = res.data.customerTypes;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error(
            "Beim Abruf der Kundentypen ist ein Fehler aufgetreten."
          );
        });
    },
    showUser() {
      this.axios
        .get(`/users/${this.$route.params.id}`)
        .then(r => {
          this.user = r.data.user;
        })
        .catch(e => {
          console.log(e);
        });
    },
    submit() {
      const url = "/users/";
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.axios
          .patch(url + this.$route.params.id, {
            user: this.user
          })
          .then(() => {
            if (this.file) {
              this.storeImg(this.$route.params.id);
            }
            if (this.$route.query.referer) {
              this.$router.push(this.$route.query.referer.toString());
            }
            this.successMethod();
          })
          .catch(e => {
            this.errorMethod(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    storeImg(id) {
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        this.axios
          .post(`/users/image/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            window.location.reload(true);
          })
          .catch(e => {
            console.log(e);
            this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
          });
      }
    },
    successMethod() {
      this.$snotify.success("Das Profil wurde erfolgreich aktualisiert.");
    },
    errorMethod(e) {
      console.log(e);
      this.$snotify.error(
        "Beim Aktualisieren des Profils ist ein Fehler aufgetreten."
      );
    }
  }
};
</script>

<style scoped></style>
