<template>
  <span class="grey--text text--darken-1">
    Hinweis:
    <slot />
  </span>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
