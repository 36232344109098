export default {
  isLoggedIn: false,
  permissions: [],
  roles: [],
  userDetails: {},
  brandUrl: "",
  settings: [],
  isMobileDrawerOpen: false,
  isMobile: false
};
