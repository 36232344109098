var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"multi-sort":"","footer-props":{
    itemsPerPageText: 'Einträge pro Seite',
    itemsPerPageOptions: [10, 25, 50, 100, -1],
    itemsPerPageAllText: 'Alle'
  }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([(_vm.editRoute)?{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","to":{ name: _vm.editRoute, params: { id: item.id } },"exact":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Ansicht")])])]}},{key:"item.created_at",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD.MM.YYYY"))+" ")]}},{key:"item.verified_at",fn:function(ref){
  var item = ref.item;
return [(item.verified_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.verified_at,"DD.MM.YYYY"))+" ")]):_c('span',[_vm._v(" Nicht signiert ")])]}},{key:"item.handover_at",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.handover_at,"DD.MM.YYYY"))+" ")]}},{key:"item.start_at",fn:function(ref){
  var item = ref.item;
return [(item.start_at && item.start_at_text)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.start_at,"DD.MM.YYYY"))+" ("+_vm._s(item.start_at_text)+") ")]):(item.start_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.start_at,"DD.MM.YYYY"))+" ")]):(item.start_at_text)?_c('span',[_vm._v(" "+_vm._s(item.start_at_text)+" ")]):_vm._e()]}},{key:"item.end_at",fn:function(ref){
  var item = ref.item;
return [(item.end_at && item.end_at_text)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.end_at,"DD.MM.YYYY"))+" ("+_vm._s(item.end_at_text)+") ")]):(item.end_at_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.end_at,"DD.MM.YYYY"))+" ")]):(item.end_at_text)?_c('span',[_vm._v(" "+_vm._s(item.end_at_text)+" ")]):_vm._e()]}},{key:"item.handover",fn:function(ref){
  var item = ref.item;
return [(item.inventoryHandover)?_c('router-link',{staticClass:"custom-link",attrs:{"to":{
        name: 'InventoryHandoversShow',
        params: { id: item.inventoryHandover.id }
      }}},[_c('span',[_vm._v(" "+_vm._s(item.inventoryHandover ? item.inventoryHandover.name : "Gelöscht")+" ")])]):_vm._e()]}},{key:"item.downloadAction",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.download(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Herunterladen")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.destroyUpload(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Löschen")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }