<template>
  <v-row dense class="fill-height">
    <v-col cols="12" md="6" class="pa-0">
      <v-img src="@/assets/login-bg.svg" height="100%" width="100%">
        <v-overlay color="primary" opacity="0.7" style="width: 50%">
          <v-card
            dark
            color="transparent"
            flat
            class="pa-16 d-flex flex-column justify-center align-center"
          >
            <v-card-title class="text-h2 text-center">
              Starten Sie jetzt voll durch mit
            </v-card-title>
            <img
              class="mt-5"
              alt="Consulting@Services"
              src="@/assets/logo.svg"
              style="filter: invert(1); width: 30%"
            />
          </v-card>
        </v-overlay>
      </v-img>
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="pa-16 white d-flex justify-center align-center"
    >
      <v-card color="white" width="70%" flat class="ma-16">
        <v-card-title class="text-h4">
          Passwort vergeben
        </v-card-title>
        <v-card-text>
          <v-alert v-model="success" dense text type="success" class="mb-6">
            Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können Sich nun
            einloggen.
          </v-alert>
          <v-btn
            v-if="success"
            class="mt-3"
            color="primary"
            block
            text
            :to="{ name: 'Login' }"
          >
            Login
          </v-btn>
          <v-form
            v-if="!success"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitResetPassword"
          >
            <v-text-field
              v-model="user.password"
              filled
              label="Passwort"
              :rules="[v => !!v || 'Passwort wird benötigt.']"
              type="password"
              clearable
            />
            <v-text-field
              v-model="user.password_confirmation"
              filled
              label="Passwort bestätigen"
              :error-messages="passwordMatchError"
              :rules="[v => !!v || 'Passwort muss bestätigt werden.']"
              type="password"
              clearable
            />
            <v-btn
              class="mt-3"
              type="submit"
              color="primary"
              block
              :disabled="!valid"
              :loading="loading"
            >
              {{
                $route.query.invite
                  ? "Passwort vergeben"
                  : "Passwort zurücksetzen"
              }}
            </v-btn>
            <v-btn
              class="mt-3"
              color="primary"
              block
              text
              :to="{ name: 'Login' }"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ResetPassword",
  data: () => ({
    success: false,
    alert: false,
    loading: false,
    valid: true,
    user: {
      email: "",
      password: "",
      password_confirmation: ""
    },
    emailRules: [
      v => !!v || "E-Mail-Adresse wird benötigt.",
      v => /.+@.+\..+/.test(v) || "E-Mail-Adresse ist ungültig."
    ]
  }),
  computed: {
    passwordMatchError() {
      if (this.user.password && this.user.password_confirmation) {
        return this.user.password === this.user.password_confirmation
          ? ""
          : "Die Passwörter müssen übereinstimmen.";
      }
      return "";
    }
  },
  created() {
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
    }
  },
  methods: {
    ...mapActions({
      vuexReset: "user/resetPassword"
    }),
    submitResetPassword() {
      if (this.$refs.form.validate()) {
        this.alert = false;
        this.loading = true;
        const { token } = this.$route.query;
        this.vuexReset({ ...this.user, token })
          .then(() => {
            // this.$snotify.success("Erfolgreich angemeldet.");
            this.success = true;
            this.loading = false;
          })
          .catch(error => {
            if (
              error.response.data &&
              error.response.data.response === "passwords.token"
            ) {
              this.$snotify.error(
                "Das Passwort konnte nicht zurückgesetzt werden. Der Link ist abgelaufen."
              );
            } else {
              this.$snotify.error(
                "Das Passwort konnte nicht zurückgesetzt werden."
              );
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style scoped></style>
