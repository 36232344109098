<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="11" lg="12">
        <v-row>
          <v-col cols="12" md="12">
            <v-card flat color="transparent">
              <v-row no-gutters>
                <v-col cols="12" md="12" class="pr-10 py-6">
                  <div class="text-h3">
                    Willkommen bei Consulting@Services!
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <v-card flat color="accent">
              <v-row no-gutters>
                <v-col cols="12" md="12" class="pr-10 py-6">
                  <v-card-title class="text-h4">
                    <dashboard-greeting />
                  </v-card-title>
                  <v-card-text class="text-h5">
                    Genießen Sie den smarten Service, den Sie verdient haben.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <div class="mt-8">
          <dashboard-client-section v-if="$hasPermissions('client')" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    cards: []
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
