var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"multi-sort":"","no-data-text":"Es liegen noch keine Daten vor.","no-results-text":"Es wurden keine Ergebnisse gefunden.","loading-text":"Lädt...","footer-props":{
    itemsPerPageText: 'Einträge pro Seite',
    itemsPerPageOptions: [10, 25, 50, 100, -1],
    itemsPerPageAllText: 'Alle'
  }},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
  var item = ref.item;
return [_c('v-img',{attrs:{"src":item.logo_url,"height":"90px","contain":"","max-width":"100px"}})]}},{key:"item.color",fn:function(ref){
  var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.color,"size":"25"}})]}},{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([(_vm.editRoute)?{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","to":{ name: _vm.editRoute, params: { id: item.id } },"exact":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Ansicht")])])]}},{key:"item.is_draft",fn:function(ref){
  var item = ref.item;
return [(item.is_draft)?_c('models-label-item',{attrs:{"label":_vm.draftLabel}}):_c('models-label-item',{attrs:{"label":_vm.noDraftLabel,"dark":""}})]}},{key:"item.created_at",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD.MM.YYYY"))+" ")]}},{key:"item.settings",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([(_vm.settingsRoute)?{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","to":{
            name: _vm.settingsRoute,
            params: { id: item.id },
            query: { settings: true }
          },"exact":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Einstellungen")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }