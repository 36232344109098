<template>
  <div>
    <v-autocomplete
      v-model="formItem.value"
      filled
      :label="formItem.label"
      :items="formItem.items"
      :placeholder="formItem.placeholder"
      hide-details="auto"
      :multiple="formItem.is_multiple"
    />
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {},
  watch: {
    formItem: {
      deep: true,
      handler(val) {
        this.$emit("update", val.value);
      }
    }
  },
  created() {},
  methods: {}
};
</script>

<style scoped></style>
