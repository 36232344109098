import axios from "axios";

export default {
  index(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/documents")
        .then(response => {
          resolve(response);
          ctx.commit("setDocuments", response.data.documents);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  indexUploadedDocuments(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/documents/uploaded")
        .then(response => {
          resolve(response);
          ctx.commit("setUploadedDocuments", response.data.uploads);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/documents", { document: payload })
        .then(response => {
          resolve(response);
          ctx.commit("setDocument", response.data.document);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  show(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/documents/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setDocument", response.data.document);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/documents/${id}`, { document: payload })
        .then(response => {
          resolve(response);
          ctx.commit("setDocument", response.data.document);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  destroy(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/documents/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setDocument", {});
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
