<template>
  <div>
    <v-row align="center">
      <v-col cols="12" xl="4" lg="5" md="6">
        <v-text-field
          v-model="search"
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12" xl="3" lg="4" md="6">
        <models-form-create-for-edit-dialog />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <settings-data-table
            :items="formsOwn"
            :headers="headers"
            :search="search"
            edit-route="FormsEdit"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Beschreibung", value: "body" },
      { text: "Entwurfsstatus ", value: "is_draft" },
      { text: "Status ", value: "label" },
      { value: "action", sortable: false }
    ]
  }),
  computed: {
    forms() {
      return this.$store.getters["form/forms"];
    },
    formsOwn() {
      return this.$store.getters["form/formsOwn"];
    }
  },
  watch: {},
  created() {
    this.indexForms();
  },
  methods: {
    ...mapActions({
      indexForms: "form/index"
    })
  }
};
</script>

<style scoped></style>
