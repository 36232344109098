<template>
  <div>
    <!--    <v-checkbox
      v-if="!formItem.items || !formItem.is_multiple"
      v-model="formItem.value"
      hide-details="auto"
      :label="formItem.label"
    />-->
    <template>
      <template v-for="(checkbox, index) in formItem.items">
        <v-checkbox
          :key="`${formItem.id}-checkbox-${index}`"
          v-model="formItem.value"
          hide-details="auto"
          :value="checkbox"
          :label="checkbox"
        />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {},
  watch: {
    formItem: {
      deep: true,
      handler(val) {
        this.$emit("update", val.value);
      }
    }
  },
  created() {},
  methods: {}
};
</script>

<style scoped></style>
