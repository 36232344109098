<template>
  <div>
    <div v-if="formItem.label" class="text-h6 mb-4">
      {{ formItem.label }}
    </div>
    <v-card class="mb-4">
      <v-data-table
        :key="dataTableKey"
        :headers="headers"
        :items="formItem.value"
      >
        <template v-slot:item.action="{ item, index }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                small
                icon
                v-on="on"
                @click="removeValue({ index: index })"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Entfernen</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <template v-for="(item, index) in formItem.items">
      <div :key="`${formItem.id}-item-${index}`" class="mb-2">
        <models-form-item-date-picker
          v-if="item.type === 'v-date-picker'"
          :form-item="item"
        />
        <models-form-item-text-field
          v-if="item.type === 'v-text-field'"
          :form-item="item"
        />
      </div>
    </template>
    <v-btn :disabled="!inputs" @click="addRow">
      Hinzufügen
      <v-icon right>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    testVal: {
      test: ""
    },
    inputs: {},
    dataTableKey: 0
  }),
  computed: {
    headers() {
      if (!this.formItem.items || !Array.isArray(this.formItem.items))
        return [];

      const headers = [];
      this.formItem.items.forEach(element => {
        headers.push({ value: element.label, text: element.label });
      });
      headers.push({ value: "action", sortable: false, align: "end" });

      return headers;
    }
  },
  watch: {
    formItem: {
      deep: true,
      handler(val) {
        this.$emit("update", val);
      }
    }
  },
  created() {},
  methods: {
    addRow() {
      let obj = {};
      if (!this.formItem.value || !Array.isArray(this.formItem.value))
        this.formItem.value = [];
      this.formItem.items.forEach(element => {
        obj[element.label] = element.value;
        element.value = "";
      });
      this.$set(this.formItem.value, this.formItem.value.length, obj);
      this.dataTableKey++;
    },
    removeValue({ index }) {
      this.formItem.value.splice(index, 1);
      this.dataTableKey++;
    }
  }
};
</script>

<style scoped></style>
