export default {
  documents(state) {
    return state.documents;
  },
  uploadedDocuments(state) {
    return state.uploadedDocuments;
  },
  document(state) {
    return state.document;
  }
};
