<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed block height="38px" v-bind="attrs" v-on="on">
        Neues Formular
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Neues Formular anlegen">
      <template v-slot:text>
        <v-form ref="form" v-model="isValid" @submit.prevent="submit">
          <v-text-field
            v-model="form.name"
            filled
            label="Titel *"
            hide-details="auto"
            :rules="[rules.required, rules.short_text]"
            counter="280"
          />
          <v-textarea
            v-model="form.body"
            filled
            label="Beschreibung *"
            hide-details="auto"
            :rules="[rules.required, rules.long_text]"
            counter="6000"
          />
        </v-form>

        <ui-help-text>
          Durch Hinzufügen wird das Formular automatisch als Entwurf
          gespeichert. Im Anschluss können Sie Inhalte festlegen.
        </ui-help-text>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          :loading="loading"
          @click="submit"
        >
          Hinzufügen
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    dialog() {
      this.$store.commit("form/setForm", {});
      this.$refs.form.resetValidation();
    }
  },
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      storeForm: "form/store"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.form.is_template = true;
      this.storeForm({ payload: { form: this.form } })
        .then(r => {
          this.$snotify.success(
            "Das Formular wurde erfolgreich als Entwurf angelegt."
          );
          this.$router.push({
            name: "SettingsFormsEdit",
            params: { id: r.data.form.id }
          });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Speichern des Formulars ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
