import axios from "axios";

export default {
  index(ctx) {
    return new Promise((resolve, reject) => {
      axios
        .get("/form-items")
        .then(response => {
          resolve(response);
          ctx.commit("setFormItems", response.data.formItems);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  store(ctx, { payload }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/form-items", payload)
        .then(response => {
          resolve(response);
          ctx.commit("setFormItem", response.data.formItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  show(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/form-items/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setFormItem", response.data.formItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/form-items/${id}`, payload)
        .then(response => {
          resolve(response);
          ctx.commit("setFormItem", response.data.formItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateOrder(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/form-items/${id}/update-order`, payload)
        .then(response => {
          resolve(response);
          ctx.commit("setFormItem", response.data.formItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateValue(ctx, { id, payload }) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/form-items/${id}/update-value`, payload)
        .then(response => {
          resolve(response);
          ctx.commit("setFormItem", response.data.formItem);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  destroy(ctx, { id }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/form-items/${id}`)
        .then(response => {
          resolve(response);
          ctx.commit("setFormItem", {});
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
