<template>
  <div class="mb-4">
    <div v-if="formItem.name" class="text-h5 mb-2" v-text="formItem.name" />
    <div v-if="formItem.description" class="body-2">
      <pre>{{ formItem.description }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
