<template>
  <v-fade-transition>
    <router-view></router-view>
  </v-fade-transition>
</template>

<script>
export default {
  name: "PassThrough"
};
</script>

<style scoped></style>
