<template>
  <div class="py-2">
    <!-- section -->
    <models-form-item-section v-if="type === 'section'" :form-item="formItem" />

    <!-- inputs -->
    <models-form-item-input-header
      v-if="type !== 'section'"
      :form-item="formItem"
    />
    <models-form-item-autocomplete
      v-if="type === 'v-autocomplete'"
      :form-item="formItem"
      @update="updateValue"
    />
    <models-form-item-checkbox
      v-if="type === 'v-checkbox'"
      :form-item="formItem"
      @update="updateValue"
    />
    <models-form-date-picker
      v-if="type === 'v-date-picker'"
      :form-item="formItem"
      @update="updateValue"
    />
    <models-form-item-radio-group
      v-if="type === 'v-radio-group'"
      :form-item="formItem"
      @update="updateValue"
    />
    <models-form-item-textarea
      v-if="type === 'v-textarea'"
      :form-item="formItem"
      @update="updateValue"
    />
    <models-form-item-text-field
      v-if="type === 'v-text-field'"
      :form-item="formItem"
      @update="updateValue"
    />
    <models-form-item-input-list
      v-if="type === '24-input-list'"
      :form-item="formItem"
      @update="updateValue"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {
    type() {
      if (this.formItem) {
        return this.formItem.type;
      }
      return "section";
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      updateValueFormItem: "formItem/updateValue"
    }),

    updateValue: _.debounce(function() {
      this.saving = true;

      this.updateValueFormItem({
        id: this.formItem.id,
        payload: { value: this.formItem.value }
      }).then(() => {
        this.$snotify.success("Gespeichert");
      });
    }, 500)
  }
};
</script>

<style scoped></style>
