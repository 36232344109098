import Middleware from "@/middleware/index";
// eslint-disable-next-line no-unused-vars
import PassThrough from "@/views/auth/views/PassThrough";
// eslint-disable-next-line no-unused-vars
import PassThroughMain from "@/views/auth/views/PassThroughMain";

export default [
  {
    path: "documents",
    component: PassThroughMain,
    children: [
      {
        path: "",
        name: "SettingsDocuments",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/settings/Documents/Index"
            )
        },
        meta: {
          barTitle: "Dokumente - Einstellungen",
          middleware: [Middleware.auth],
          permissions: ["system-admin"]
        }
      },
      {
        path: "form",
        name: "SettingsDocumentsCreate",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/settings/Documents/Form"
            )
        },
        meta: {
          barTitle: "Dokument erstellen",
          middleware: [Middleware.auth],
          permissions: ["system-admin"]
        }
      },
      {
        path: "form/:id",
        name: "SettingsDocumentsEdit",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "../views/auth/views/settings/Documents/Form"
            )
        },
        meta: {
          barTitle: "Dokument bearbeiten",
          middleware: [Middleware.auth],
          permissions: ["system-admin"]
        }
      }
    ]
  },
  {
    path: "forms",
    component: PassThroughMain,
    children: [
      {
        path: "",
        name: "SettingsForms",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/auth/views/settings/Forms/Index"
            )
        },
        meta: {
          barTitle: "Formulare - Einstellungen",
          middleware: [Middleware.auth],
          permissions: ["system-admin"]
        }
      },
      {
        path: "form",
        name: "SettingsFormsCreate",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/auth/views/settings/Forms/Form"
            )
        },
        meta: {
          barTitle: "Formular erstellen",
          middleware: [Middleware.auth],
          permissions: ["system-admin"]
        }
      },
      {
        path: "form/:id",
        name: "SettingsFormsEdit",
        components: {
          main: () =>
            import(
              /* webpackChunkName: "auth" */ "@/views/auth/views/settings/Forms/Form"
            )
        },
        meta: {
          barTitle: "Formular bearbeiten",
          middleware: [Middleware.auth],
          permissions: ["system-admin"]
        }
      }
    ]
  }
];
