<template>
  <v-app>
    <!--    <v-navigation-drawer app right color="white" floating>
      <router-view name="rightDrawer"> </router-view>
    </v-navigation-drawer>-->
    <v-app-bar v-if="$route.meta.nav === true" app color="white">
      <v-spacer />
      <v-btn>
        Login
      </v-btn>
    </v-app-bar>
    <v-main class="white">
      <v-container fluid class="pa-0 fill-height">
        <router-view name="main" />
      </v-container>
      <vue-snotify></vue-snotify>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Wrapper"
};
</script>

<style scoped></style>
