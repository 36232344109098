export default {
  setForms(state, payload) {
    state.forms = payload;
  },
  setFormsOwn(state, payload) {
    state.formsOwn = payload;
  },
  setForm(state, payload) {
    state.form = payload;
  }
};
