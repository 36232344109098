<template>
  <v-navigation-drawer
    :key="drawerKey"
    v-model="isMobileDrawerOpen"
    color="white"
    app
    :expand-on-hover="!isDrawerOpen && !isMobile"
    fixed
    width="280px"
    clipped
    :mobile-breakpoint="600"
    bottom
  >
    <v-list nav rounded>
      <v-list-item class="mt-2" link :to="{ name: 'Dashboard' }" exact>
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="font-weight-medium black--text">
          Dashboard
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider
      v-if="$hasPermissions('system-admin')"
      class="grey lighten-1"
      style="border-width: 1px"
    />

    <v-list v-if="$hasPermissions('system-admin')" nav rounded>
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Einstellungen
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <!--        <v-list-item link :to="{ name: 'SettingsDocuments' }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Dokumente
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
        <v-list-item link :to="{ name: 'SettingsForms' }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              Formulare
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action sub-group class="mb-2">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Personen
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="{ name: 'SettingsUsers' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Nutzer
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--          <v-list-item link :to="{ name: 'SettingsTeams' }">
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">
                Teams
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
        </v-list-group>
        <v-list-item link :to="{ name: 'SettingsInstanceSettings' }">
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              System
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <v-list nav rounded class="hidden-xs-only">
        <v-list-item link @click="setDrawer">
          <v-list-item-icon>
            <v-icon>{{
              isDrawerOpen
                ? "mdi-chevron-double-left"
                : "mdi-chevron-double-right"
            }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">
              {{
                isDrawerOpen
                  ? "Seitenleiste einklappen"
                  : "Seitenleiste fixieren"
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Drawer",
  data: () => ({
    drawerKey: 0
  }),
  computed: {
    userDetails() {
      return this.$store.getters["state/userDetails"];
    },
    isDrawerOpen() {
      return this.$store.getters["state/userDetails"].is_drawer_open;
    },
    isMobileDrawerOpen: {
      get() {
        return this.$store.getters["state/isMobileDrawerOpen"];
      },
      set(value) {
        this.$store.commit("state/setIsMobileDrawerOpen", value);
      }
    },
    isMobile() {
      return this.$store.getters["state/isMobile"];
    }
  },
  watch: {
    isMobile(val) {
      if (val === false) {
        setTimeout(function() {
          this.isMobileDrawerOpen = true;
        }, 200);
      }
    }
  },
  created() {},
  methods: {
    ...mapActions({
      setDrawerAction: "state/setDrawer"
    }),
    setDrawer() {
      this.setDrawerAction({
        id: this.userDetails.id,
        drawer: !this.userDetails.is_drawer_open
      });
      this.userDetails.is_drawer_open = !this.userDetails.is_drawer_open;
      this.drawerKey++;
    }
  }

  // TODO: active states
};
</script>

<style scoped></style>
