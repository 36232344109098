<template>
  <v-row dense class="fill-height">
    <v-col cols="12" md="6" class="pa-0">
      <v-img src="@/assets/login-bg.svg" height="100%" width="100%">
        <v-overlay color="primary" opacity="0.7" style="width: 50%">
          <v-card
            dark
            color="transparent"
            flat
            class="pa-16 d-flex flex-column justify-center align-center"
          >
            <v-card-title class="text-h2 text-center">
              Starten Sie jetzt voll durch mit
            </v-card-title>
            <img
              class="mt-5"
              alt="Consulting@Services"
              src="@/assets/logo.svg"
              style="filter: invert(1); width: 30%"
            />
          </v-card>
        </v-overlay>
      </v-img>
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="pa-16 white d-flex justify-center align-center"
    >
      <v-card color="white" width="70%" flat class="ma-16">
        <v-card-title class="text-h4">
          {{ $route.query.email ? "Password anfordern" : "Password vergessen" }}
        </v-card-title>
        <v-card-text>
          <v-alert v-model="success" dense text type="success" class="mb-6">
            Die E-Mail wurde erfolgreich versendet! Bitte überprüfen Sie ihr
            Postfach und ggf. auch Ihren Spam-Ordner.
          </v-alert>
          <v-btn
            v-if="success"
            class="mt-3"
            color="primary"
            block
            text
            :to="{ name: 'Login' }"
          >
            Login
          </v-btn>
          <v-form
            v-if="!success"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitForgotPassword"
          >
            <v-text-field
              v-model="email"
              class="mt-1"
              filled
              label="E-Mail-Adresse"
              :rules="emailRules"
              clearable
            >
            </v-text-field>
            <v-btn
              class="mt-3"
              type="submit"
              color="primary"
              block
              :disabled="!valid"
              :loading="loading"
            >
              Email senden
            </v-btn>
            <v-btn
              class="mt-3"
              color="primary"
              block
              text
              :to="{ name: 'Login' }"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    success: false,
    alert: false,
    loading: false,
    valid: true,
    email: "",
    emailRules: [
      v => !!v || "E-Mail-Adresse wird benötigt.",
      v => /.+@.+\..+/.test(v) || "E-Mail-Adresse ist ungültig."
    ]
  }),
  computed: {},
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    ...mapActions({
      vuexForgot: "user/forgotPassword"
    }),
    submitForgotPassword() {
      if (this.$refs.form.validate()) {
        this.alert = false;
        this.loading = true;
        this.vuexForgot({ email: this.email })
          .then(() => {
            // this.$snotify.success("Erfolgreich angemeldet.");
            this.success = true;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.$snotify.error("Die E-Mail konnte nicht versendet werden.");
          });
      }
    }
  }
};
</script>

<style scoped></style>
