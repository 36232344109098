<template>
  <v-card
    color="accent lighten-2"
    width="100%"
    height="300px"
    outlined
    class="pa-8 d-flex flex-column justify-center align-center"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <input
      id="assetsFieldHandle"
      ref="file"
      type="file"
      style="opacity: 0; position: absolute; height: 100%; width: 100%; cursor: pointer;"
      @change="onChange"
    />
    <label
      for="assetsFieldHandle"
      style="height: 100%;"
      class="d-flex flex-column justify-space-between align-center text-center"
    >
      <img src="@/assets/upload.svg" alt="Upload" style="max-height: 150px" />
      <v-fade-transition>
        <span v-if="!file" key="1" style="height: 44px">
          Ziehen Sie die Datei in den Kasten, oder <b>klicken</b> Sie.
        </span>
        <span v-if="file" key="2" style="height: 44px">
          Um die aktuelle Datei zu überschreiben, ziehen Sie sie in den Kasten,
          oder <b>klicken</b> Sie.
        </span>
      </v-fade-transition>
    </label>
  </v-card>
</template>

<script>
export default {
  props: {
    fileProp: {
      type: File,
      default: null
    }
  },
  data: () => ({
    file: null
  }),
  watch: {
    fileProp(val) {
      this.file = val;
    },
    file(val) {
      console.log(val);
      this.$emit("input", val);
    }
  },
  created() {
    this.file = this.fileProp;
  },
  methods: {
    onChange() {
      // console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
    },
    remove() {
      this.file = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("lighten-3")) {
        event.currentTarget.classList.add("lighten-3");
        event.currentTarget.classList.remove("lighten-2");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("lighten-3");
      event.currentTarget.classList.add("lighten-2");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("lighten-2");
      event.currentTarget.classList.remove("lighten-3");
    }
  }
};
</script>

<style scoped></style>
