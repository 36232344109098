<template>
  <div>
    <v-menu
      :ref="`date-menu-${formItem.id}`"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          v-bind="attrs"
          prepend-inner-icon="mdi-calendar"
          filled
          :label="formItem.label"
          :placeholder="formItem.placeholder"
          hide-details="auto"
          readonly
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="formItem.value"
        @input="menu = false"
        @change="update"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    // functional
    menu: false
  }),
  computed: {
    dateFormatted() {
      if (!this.formItem.value) return null;

      const [year, month, day] = this.formItem.value.split("-");
      return `${day}.${month}.${year}`;
    }
  },
  watch: {},
  created() {},
  methods: {
    update() {
      this.$emit("update", this.formItem);
    }
  }
};
</script>

<style scoped></style>
