<template>
  <div>
    <v-text-field
      v-model="formItem.value"
      filled
      :label="formItem.label"
      :placeholder="formItem.placeholder"
      hide-details="auto"
      @input="update"
    />
  </div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  computed: {},
  created() {},
  methods: {
    update() {
      this.$emit("update", this.formItem.value);
    }
  }
};
</script>

<style scoped></style>
