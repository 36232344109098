<template>
  <div style="height: 100%">
    <v-hover v-slot="{ hover }">
      <v-card height="100%" :elevation="hover ? 12 : 2">
        <v-card class="mt-6">
          <v-card-title class="grey darken-2 white--text body-1 py-1">
            {{ comment.creator.name }}
            {{ comment.created_at | moment("calendar") }}
          </v-card-title>
          <v-card-text class="black--text py-2">
            {{ comment.body }}
          </v-card-text>
          <v-expansion-panels flat hover>
            <v-expansion-panel v-if="comment.children.length > 0">
              <v-expansion-panel-header class="px-4">
                <span class="primary--text text--darken-4"
                  >Antworten ({{ comment.children.length }}) ansehen</span
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mx-n2">
                <v-card
                  v-for="item in comment.children"
                  :key="item.id"
                  flat
                  color="grey lighten-4"
                  class="mt-3"
                >
                  <v-card-title class="grey darken-2 white--text body-1 py-1">
                    {{ item.creator.name }}
                    {{ item.created_at | moment("calendar") }}
                  </v-card-title>
                  <v-card-text class="black--text py-2">{{
                    item.body
                  }}</v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-text>
            <div>
              <div class="text-overline">
                Auf Kommentar antworten
              </div>
              <v-form v-model="commentValid">
                <v-textarea
                  v-model="body"
                  filled
                  label="Neue Antwort"
                  rows="3"
                />
              </v-form>
              <div class="justify-end d-flex">
                <v-btn
                  color="primary"
                  :disabled="!commentValid"
                  @click="storeComment()"
                >
                  Absenden
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card flat class="mt-4"> </v-card>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    commentProp: {
      type: Object,
      default: () => {}
    },
    share: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    commentValid: false,
    comment: {},
    body: ""
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  watch: {
    /* inquiryProp(val) {
      this.inquiry = val;
    },
    inquiry(val) {
      this.$emit("updateInquiry", val);
    } */
  },
  created() {
    this.comment = this.commentProp;
  },
  methods: {
    storeComment() {
      const item = {};
      item.body = this.body;
      item.commentable_type = "App\\Models\\Share";
      item.commentable_id = this.share.id;
      item.parent_id = this.comment.id;
      this.axios
        .post("/comments", {
          comment: item
        })
        .then(r => {
          this.$snotify.success("Der Kommentar wurde erfolgreich gespeichert.");
          this.$emit("pushComment", r.data.comment);
          this.body = "";
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Der Kommentar konnte nicht gespeichert werden.");
        });
    }
  }
};
</script>

<style scoped></style>
