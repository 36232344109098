import axios from "axios";

export default {
  forgotPassword(ctx, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/forgot-password", payload)
        .then(response => {
          resolve(response);
          // this.$router.push({name: 'Dashboard'});
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  resetPassword(ctx, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/reset-password", payload)
        .then(response => {
          resolve(response);
          // this.$router.push({name: 'Dashboard'});
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  update(ctx, payload, id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/users/${id}`, payload)
        .then(response => {
          resolve(response);
          // this.$router.push({name: 'Dashboard'});
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
