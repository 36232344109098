import { render, staticRenderFns } from "./item.vue?vue&type=template&id=376669a1&scoped=true&"
import script from "./item.vue?vue&type=script&lang=js&"
export * from "./item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376669a1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VDivider,VIcon,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSlideXTransition})
