<template>
  <v-form
    ref="form"
    v-model="isValid"
    lazy-validation
    @submit.prevent="submit()"
  >
    <div class="text-overline">
      Allgemein
    </div>

    <v-select
      v-if="type === 'settings'"
      v-model="user.type"
      label="Typ"
      :items="typeItems"
      filled
      :rules="[rules.required]"
      required
    />

    <v-select
      v-model="user.title"
      label="Titel"
      :items="titleItems"
      filled
      required
      clearable
    />

    <v-text-field
      v-model="user.first_name"
      filled
      label="Vorname"
      :rules="[rules.required, rules.short_text]"
      required
      :counter="280"
    />

    <v-text-field
      v-model="user.last_name"
      filled
      label="Nachname"
      :rules="[rules.required, rules.short_text]"
      :counter="280"
    />
    <div class="text-overline mt-4">
      Kontakt
    </div>
    <v-text-field
      v-model="user.email"
      filled
      label="E-Mail"
      :rules="[rules.required, rules.short_text, rules.email]"
      :error-messages="emailError"
      :counter="280"
      @input="checkEmail"
    />

    <v-text-field
      v-model="user.phone1"
      filled
      label="Telefon"
      :rules="[rules.short_text, rules.phone]"
      :counter="280"
    />

    <v-text-field
      v-model="user.phone2"
      filled
      label="Mobil"
      :rules="[rules.short_text, rules.phone]"
      :counter="280"
    />

    <div class="text-overline mt-4">
      Adresse
    </div>

    <v-text-field
      v-model="user.address1"
      filled
      label="Straße und Hausnummer"
      :rules="[rules.short_text]"
      :counter="280"
    />

    <v-text-field
      v-model="user.address2"
      filled
      label="Adresszusatz"
      :rules="[rules.short_text]"
      :counter="280"
    />

    <v-row>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="user.zip"
          filled
          label="PLZ"
          :rules="[rules.short_text]"
          :counter="280"
        />
      </v-col>
      <v-col cols="12" md="7">
        <v-text-field
          v-model="user.city"
          filled
          label="Ort"
          :rules="[rules.short_text]"
          :counter="280"
        />
      </v-col>
    </v-row>

    <div
      v-if="
        (user.type === 'extern' && $hasPermissions('consultant permission')) ||
          ($hasPermissions('settings permission') && user.type === 'extern')
      "
    >
      <div class="text-overline mt-4">
        Kundenspezifisch
      </div>
      <v-text-field
        v-model="user.label"
        filled
        label="Kundennummer"
        :rules="[rules.short_text]"
        :counter="280"
      />

      <v-autocomplete
        v-model="user.customer_type_id"
        :items="customerTypes"
        filled
        label="Kundentyp"
        hide-no-data
        clearable
        item-text="name"
        item-value="id"
      >
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-avatar
              v-if="data.item.color"
              size="20"
              :color="data.item.color"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="data.item.description">
              {{ data.item.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>

      <!--      <v-select
        :items="companies"
        label="Unternehmen"
        filled
        v-model="user.company_id"
        item-text="name"
        item-value="id"
        clearable
      />-->
      <div v-if="type === 'settings'">
        <div class="text-overline mt-4">
          Berechtigungen
        </div>

        <v-select
          v-model="user.roles"
          :items="roles_extern"
          item-text="name_de"
          item-value="name"
          label="Rollen"
          :rules="[rules.required]"
          multiple
          filled
        />
      </div>
    </div>

    <div v-if="user.type === 'intern'">
      <div v-if="type === 'settings'">
        <div class="text-overline mt-4">
          Mitarbeiterspezifisch
        </div>
        <v-text-field
          v-model="user.label"
          filled
          label="Personalnummer"
          :rules="[rules.short_text]"
          :counter="280"
        />
      </div>

      <div>
        <div class="text-overline mt-4">
          Profilbild
        </div>

        <img
          v-if="user.image_url"
          :src="user.image_url"
          alt="Profilbild"
          style="height: 100px; max-width: 300px;"
        />
        <util-file-drop :file-prop="file" @pass="file = $event" />
      </div>

      <div v-if="type === 'settings'">
        <div class="text-overline mt-4">
          Berechtigungen
        </div>
        <v-select
          v-model="user.roles"
          :items="user.type === 'intern' ? roles_intern : roles_extern"
          item-text="name_de"
          item-value="name"
          label="Rollen"
          multiple
          filled
        />
      </div>
    </div>
    <div v-if="!user.id">
      <div class="text-overline mt-4">
        Zugangsdaten
      </div>
      <v-radio-group v-model="user.is_invited" row>
        <v-radio label="Nutzer wird eingeladen." :value="true" />
        <v-radio label="Ein Passwort wird vergeben." :value="false" />
      </v-radio-group>
      <div v-if="!user.is_invited">
        <v-text-field
          v-model="user.password"
          type="password"
          clearable
          filled
          :rules="[rules.required, rules.password_length]"
          label="Passwort"
        />

        <v-text-field
          v-model="user.password_confirmation"
          filled
          type="password"
          clearable
          :error-messages="passwordMatchError"
          :rules="[rules.required, rules.password_length]"
          label="Passwort bestätigen"
        />
      </div>
    </div>

    <div v-else class="mt-4">
      <v-chip v-if="user.is_invited" class="mb-2">Ist eingeladen</v-chip>
      <v-text-field
        v-model="user.password"
        type="password"
        clearable
        filled
        :rules="[rules.password_length]"
        label="Passwort"
      />

      <v-text-field
        v-model="user.password_confirmation"
        filled
        type="password"
        clearable
        :error-messages="passwordMatchError"
        :rules="[rules.password_length]"
        label="Passwort bestätigen"
      />
    </div>

    <div
      v-if="
        userDetails.id === user.id && $hasPermissions('consultant permission')
      "
    >
      <div class="text-overline mt-4">
        Einstellungen
      </div>
      <v-select
        v-model="user.report_setting"
        filled
        :items="[
          { value: null, text: 'Niemals' },
          { value: 'daily', text: 'Täglich' },
          { value: 'weekly', text: 'Wöchentlich' }
        ]"
        label="Statusbericht"
      />
    </div>

    <div
      class="d-flex mt-6"
      :class="type === 'settings' ? 'justify-space-between' : 'justify-end'"
    >
      <div v-if="type === 'settings'">
        <v-dialog v-model="deleteDialog" persistent max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn depressed v-bind="attrs" v-on="on">
              Löschen
              <v-icon color="error" right>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              Wollen Sie diesen Nutzer wirklich löschen?
            </v-card-title>
            <v-card-text>
              Wenn Sie einen Nutzer löschen wird dieser unwiderruflich aus dem
              System entfernt und bestehende Verknüpfungen mit anderen Objekten
              werden aufgehoben.
            </v-card-text>
            <v-card-actions class="px-4">
              <v-spacer />
              <v-btn text @click="deleteDialog = false">
                Abbrechen
              </v-btn>
              <v-btn
                color="error"
                class="ml-2"
                @click="
                  policyDeleteUser();
                  deleteDialog = false;
                "
              >
                Löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div>
        <v-btn v-if="type === 'dialog'" text exact @click="$emit('closeDialog')"
          >Abbrechen</v-btn
        >
        <v-btn
          v-if="type === 'settings'"
          text
          :to="{ name: 'SettingsUsers' }"
          exact
        >
          Abbrechen
        </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          type="submit"
          :disabled="!isValid"
          :loading="loading"
        >
          Speichern
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    userId: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    deleteDialog: false,
    policyDelete: {
      to_assignable_type: "",
      to_assignable_id: ""
    },
    loading: false,
    isValid: false,
    typeItems: ["extern", "intern"],
    user: {
      title: null,
      first_name: "",
      last_name: "",
      email: "",
      phone1: "",
      phone2: "",
      address1: "",
      address2: "",
      zip: "",
      city: "",
      password: "",
      customer_type_id: null,
      company_id: null,
      is_invited: true,
      type: "intern",
      roles: null,
      label: "",
      teams: [],
      report_setting: null
    },
    teams: [],
    customerTypes: [],
    companies: [],
    file: "",
    roles_extern: [],
    roles_intern: [],
    roleItems: [],
    emailIsTaken: false,
    users: []
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    titleItems() {
      return this.$store.getters["application/userTitles"];
    },
    passwordMatchError() {
      if (this.user.password && this.user.password_confirmation) {
        return this.user.password === this.user.password_confirmation
          ? ""
          : "Die Passwörter müssen übereinstimmen.";
      }
      return "";
    },
    emailError() {
      if (this.emailIsTaken) {
        return "Die E-Mail-Adresse ist bereits vergeben.";
      }
      return "";
    },
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to) {
      if (this.type === "settings" || this.type === "profile") {
        if (this.$route.params.id) {
          this.showUser(this.$route.params.id);
        }
      } else if (this.type === "dialog") {
        this.user.type = "extern";
        if (this.userId) {
          this.showUser(this.userId);
        }
      }

      this.indexTeams();

      this.indexRoles();
    }
  },
  created() {
    if (this.type === "settings" || this.type === "profile") {
      if (this.$route.params.id) {
        this.showUser(this.$route.params.id);
      }
    } else if (this.type === "dialog") {
      this.user.type = "extern";
      if (this.userId) {
        this.showUser(this.userId);
      }
    }

    this.indexTeams();

    this.indexRoles();
  },
  methods: {
    storeImg(id) {
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        this.axios
          .post(`users/image/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            window.location.reload(true);
          })
          .catch(e => {
            console.log(e);
            this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
          });
      }
    },
    submit() {
      const url = "/users";
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id || this.userId) {
          const id =
            this.type === "dialog" ? this.userId : this.$route.params.id;
          this.axios
            .patch(`${url}/${id}`, {
              user: this.user
            })
            .then(r => {
              if (this.file) {
                this.storeImg(id);
              }
              this.successMethod(r);
            })
            .catch(e => {
              this.errorMethod(e);
            });
        } else {
          this.axios
            .post(url, {
              user: this.user,
              email: this.user.email
            })
            .then(r => {
              if (this.file) {
                this.storeImg(r.data.id);
              }
              this.successMethod(r);
            })
            .catch(e => {
              this.errorMethod(e);
            });
        }
      }
    },
    showUser(id) {
      this.axios
        .get(`/users/${id}`)
        .then(r => {
          this.user = { ...r.data.user };
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Nutzers ist ein Fehler aufgetreten."
          );
        });
    },
    successMethod(r) {
      this.loading = false;
      switch (this.type) {
        case "settings":
          this.$router.push({
            name: "SettingsUsers"
          });
          break;
        case "dialog":
          this.$emit("patchUser", r.data.id);
          this.$emit("storeUser", r.data.id);
          break;
        case "profile":
          if (this.$route.query.referer) {
            this.$router.push(this.$route.query.referer.toString());
          }
          break;
      }
      this.$snotify.success("Der Nutzer wurde erfolgreich gespeichert.");
    },
    errorMethod(e) {
      this.loading = false;
      console.log(e);
      this.$snotify.error(
        "Beim Speichern des Nutzers ist ein Fehler aufgetreten."
      );
    },

    indexCompanies() {
      this.axios
        .get("/companies")
        .then(res => {
          this.companies = res.data.companies;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error(
            "Beim Abruf der Unternehmen ist ein Fehler aufgetreten."
          );
        });
    },
    indexTeams() {
      this.axios
        .get("/teams")
        .then(res => {
          this.teams = res.data.teams;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error(
            "Beim Abruf der Teams ist ein Fehler aufgetreten."
          );
        });
    },
    indexCustomerTypes() {
      this.axios
        .get("/customer-types")
        .then(res => {
          this.customerTypes = res.data.customerTypes;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error(
            "Beim Abruf der Kundentypen ist ein Fehler aufgetreten."
          );
        });
    },
    indexRoles() {
      this.axios
        .get("/roles")
        .then(res => {
          this.roles_extern = res.data.roles_extern;
          this.roles_intern = res.data.roles_intern;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error(
            "Beim Abruf der Rollen ist ein Fehler aufgetreten."
          );
        });
    },
    checkEmail() {
      const urlAppend = this.user.id ? `?id=${this.user.id}` : "";

      if (this.user.email.length > 4 && /.+@.+\..+/.test(this.user.email))
        this.axios
          .get(`/users/check-email/${this.user.email}${urlAppend}`)
          .then(r => {
            r.data.status === "taken"
              ? (this.emailIsTaken = true)
              : (this.emailIsTaken = false);
          });
    },

    indexUsers() {
      this.axios
        .get("/users")
        .then(r => {
          this.users = r.data.users_intern;
        })
        .catch(e => {
          console.log(e);
        });
    },
    policyDeleteUser() {
      this.axios
        .post(
          `/users/${this.$route.params.id}/policy-delete`,
          this.policyDelete
        )
        .then(() => {
          this.$router.push({ name: "SettingsUsers" });
          this.$snotify.success("Der Nutzer wurde erfolgreich gelöscht.");
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Löschen des Nutzers ist ein Fehler aufgetreten."
          );
        });
    }
  }
};
</script>

<style scoped></style>
