<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Datei hochladen
        <v-icon right dark>mdi-upload-outline</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Datei hochladen">
      <template v-slot:text>
        <v-fade-transition>
          <div v-if="!isUploaded" key="notUploaded">
            <ui-upload-form
              :key="uploadFormKey"
              :file-prop="file"
              @input="file = $event"
            />
            <v-slide-y-transition>
              <v-form
                v-if="!loading && file"
                v-model="isValid"
                class="mt-4"
                @submit.prevent="submit"
              >
                <div class="mb-1 text-h6">
                  {{ file.name }}
                </div>
                <div class="mb-2">
                  Geben Sie der Datei eine Bezeichnung, sodass Sie leichter
                  zuzuordnen ist.
                </div>
                <v-text-field
                  v-model="name"
                  filled
                  :rules="[rules.required, rules.short_text]"
                  label="Bezeichnung*"
                  placeholder="z. B. Steuererklärung 2019, Personalausweis Vorne"
                />
              </v-form>
              <div v-if="loading && file">
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ file.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-progress-linear
                  v-model="uploadProgress"
                  color="secondary"
                  height="25"
                >
                  {{ uploadProgress }}%
                </v-progress-linear>
              </div>
            </v-slide-y-transition>
          </div>
          <div v-else key="uploaded">
            <sweetalert-icon icon="success" />
            <div class="text-h6 text-center">
              Die Datei wurde erfolgreich hochgeladen!
            </div>
          </div>
        </v-fade-transition>
      </template>

      <template v-slot:actions>
        <v-fade-transition>
          <div v-if="!isUploaded" key="notUploadedButtons" style="width: 100%">
            <v-btn
              color="primary"
              block
              :disabled="!isValid || !file"
              :loading="loading"
              @click="submit"
            >
              Hochladen
              <v-icon right dark>
                mdi-upload-outline
              </v-icon>
            </v-btn>
            <v-btn text block @click="close">
              Abbrechen
            </v-btn>
          </div>
          <div v-else key="uploadedButtons" style="width: 100%">
            <v-btn color="primary" block @click="close">
              Okay
            </v-btn>
          </div>
        </v-fade-transition>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    itemId: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    item: {},
    file: null,
    name: "",
    // functional
    dialog: false,
    isValid: false,
    loading: false,

    uploadFormKey: 0,
    uploadProgress: 0,

    isUploaded: false
  }),
  computed: {
    uploadRequest() {
      return this.$store.getters["inquiryItem/inquiryItem"]
        .currentUploadRequest;
    },
    inquiryItem() {
      return this.$store.getters["inquiryItem/inquiryItem"];
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      showInventoryObject: "inventoryObject/show"
    }),

    storeFile() {
      this.loading = true;
      const formData = new FormData();

      formData.append("file", this.file);
      formData.append("name", this.name);

      this.axios
        .post(`upload/inventoryObject/${this.itemId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadProgress = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            );
          }.bind(this)
        })
        .then(() => {
          this.$snotify.success("Die Datei wurde erfolgreich hochgeladen.");
          this.isUploaded = true;
          this.showInventoryObject({ id: this.itemId });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.file = null;
      this.uploadFormKey++;
      this.uploadProgress = 0;
      this.isUploaded = false;
      this.$emit("close");
    },

    submit() {
      this.loading = true;
      // function
      this.storeFile();
    }
  }
};
</script>

<style scoped></style>
