export default {
  setDocuments(state, payload) {
    state.documents = payload;
  },
  setUploadedDocuments(state, payload) {
    state.uploadedDocuments = payload;
  },
  setDocument(state, payload) {
    state.document = payload;
  }
};
