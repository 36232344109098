<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed block height="38px" v-bind="attrs" v-on="on">
        Neues Formular
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>
    <ui-dialog-card title="Neues Formular anlegen">
      <template v-slot:text>
        <ui-help-text>
          Durch eine Auswahl wird das Formular automatisch als Entwurf
          gespeichert.
        </ui-help-text>
        <v-row class="mt-2">
          <v-col md="12">
            <v-card
              v-for="form in forms"
              :key="form.id"
              link
              class="mt-2"
              hover
              color="primary"
              dark
              @click="storeFromTemplate({ id: form.id })"
            >
              <v-card-title>
                {{ form.name }}
              </v-card-title>
              <v-card-text
                v-if="form.body"
                class="white--text"
                v-text="form.body"
              />
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions>
        <v-spacer />
        <v-btn text class="mr-2" @click="close">
          Abbrechen
        </v-btn>
      </template>
    </ui-dialog-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    // functional
    dialog: false,
    isValid: false,
    loading: false
  }),
  computed: {
    forms() {
      return this.$store.getters["form/forms"];
    },
    formsOwn: {
      set(val) {
        this.$store.commit("form/setFormsOwn", val);
      },
      get() {
        return this.$store.getters["form/formsOwn"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    // this.item = this.itemProp ? this.itemProp : this.item;
  },
  methods: {
    ...mapActions({
      storeFromTemplateForm: "form/storeFromTemplate"
    }),

    // functional
    close() {
      this.dialog = false;
      this.loading = false;
      this.$emit("close");
    },

    storeFromTemplate({ id }) {
      this.storeFromTemplateForm({ templateId: id }).then(r => {
        this.close();
        this.formsOwn.push(r.data.form);
      });
    }
  }
};
</script>

<style scoped></style>
