<template>
  <v-chip small color="primary" dark>
    <v-icon small left>
      mdi-alert-circle-outline
    </v-icon>
    Noch nicht freigegeben
  </v-chip>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
