<template>
  <v-menu top offset-y offset-x open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" icon small v-on="on">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>
    <v-card max-width="500px">
      <v-card-title v-if="$slots.title" class="heading">
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text>
        <slot name="text"></slot>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
