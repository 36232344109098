<template>
  <div>
    <div class="mb-3">
      Wählen Sie einen Element-Typen.
    </div>
    <v-row>
      <template v-for="(type, index) in formItemTypes">
        <v-col :key="`form-item-type-${index}`" md="6">
          <v-card hover link height="100%" @click="item.type = type.value">
            <v-card-title v-text="type.name" />
            <!--            <v-card-text v-if="type.description" v-text="type.description" />-->
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    formItemTypes: [
      {
        value: "section",
        name: "Abschnitt",
        description: ""
      },
      {
        value: "v-autocomplete",
        name: "Dropdown-Auswahl",
        description:
          "Verschiedene Auswahlmöglichkeiten. Einzelne oder mehrfache Auswahl."
      },
      {
        value: "v-checkbox",
        name: "Mehrfach-Auswahl (Checkbox)",
        description: ""
      },
      {
        value: "v-date-picker",
        name: "Datumauswahl mit Kalender",
        description: ""
      },
      {
        value: "v-radio-group",
        name: "Einzel-Auswahl (Radio)",
        description: ""
      },
      {
        value: "v-textarea",
        name: "Text (mehrzeilig)",
        description: ""
      },
      {
        value: "v-text-field",
        name: "Text (einzeilig)",
        description: ""
      },
      {
        value: "24-input-list",
        name: "Liste mit anpassbaren Einträgen",
        description: ""
      }
    ]
  }),
  computed: {
    item: {
      set(val) {
        this.$store.commit("formItem/setFormItem", val);
      },
      get() {
        return this.$store.getters["formItem/formItem"];
      }
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
